<template>
  <div class="login">
    <v-container class="text-xs-center">
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success btn-back"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
      <v-layout child-flex justify-center align-center wrap>
        <v-flex fill-height>
          <v-card flat>
            <v-card-title primary-title class="login-title justify-center">
              <h2 class="m-auto">Login ke akun anda</h2>
            </v-card-title>
            <v-form>
              <div
                v-if="submitted && !$v.login_data.no_hp.required"
                class="invalid-feedback"
              >
                Nomor HP harus diisi
              </div>
              <v-text-field
                solo
                placeholder="Nomor handphone"
                name="Username"
                v-model="login_data.no_hp"
                :class="{
                  'is-invalid': submitted && $v.login_data.no_hp.$error,
                }"
              ></v-text-field>
              <div
                v-if="submitted && !$v.login_data.password.required"
                class="invalid-feedback"
              >
                Password harus diisi
              </div>
              <v-text-field
                solo
                placeholder="Password Anda"
                name="Password"
                type="password"
                v-model="login_data.password"
                :class="{
                  'is-invalid': submitted && $v.login_data.password.$error,
                }"
              ></v-text-field>
              <v-alert type="error" v-show="response !== null">
                {{ response }}</v-alert
              >
              <v-card-actions>
                <v-btn large block class="btn-login" @click="Login()"
                  >Login</v-btn
                >
              </v-card-actions>
              <a @click="$router.push('forgot-password')" class="white">
                <p class="text-center">Lupa password ?</p>
              </a>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Login } from "../services/api";
import helper from "../helpers/auth";

export default {
  data: () => ({
    login_data: {
      no_hp: "",
      password: "",
    },
    user_data: {
      email: "",
      gender: "",
      id: "",
      nama: "",
      no_hp: "",
      tanggal_lahir: "",
      token: "",
    },
    slice_hp: "",
    submitted: false,
    response: null,
  }),
  validations: {
    login_data: {
      no_hp: { required },
      password: { required },
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    Login() {
      this.slice_hp = this.login_data.no_hp.slice(
        this.login_data.no_hp.length - 4
      );

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        Login(this.login_data)
          .then((data) => {
            console.log(data);
            const token = data.data.Token;
            localStorage.setItem("user-token", token); // store the token in localstorage
            this.user_data.token = data.data.Token;
            this.user_data.email = data.data.data.email;
            this.user_data.gender = data.data.data.gender;
            this.user_data.id = data.data.data.id;
            this.user_data.nama = data.data.data.nama;
            this.user_data.no_hp = data.data.data.no_hp;
            this.user_data.tanggal_lahir = data.data.data.tanggal_lahir;
            console.log(this.user_data);
            helper.setData(this.user_data);
            // this.$router.push({
            //   path: "/",
            // });
            location.reload();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
              if (err.response.data.message == "User inactive!") {
                this.$router.push({
                  name: "verifikasi",
                  params: {
                    no_hp: this.login_data.no_hp,
                    slice_hp: this.slice_hp,
                  },
                });
              }
              this.response = err.response.data.message;
              return;
            }
            if (err.response.status == 404) {
              if (err.response.data.message == "User Not Found.") {
                this.response = err.response.data.message;
                return;
              }
            }
          });
      }
    },
  },
};
</script>
<style>
@import "../assets/css/register.component.scss";
</style>